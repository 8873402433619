var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("a-form", {
    staticStyle: {
      width: "513px"
    },
    attrs: {
      form: _vm.createFormprice,
      labelCol: {
        style: "width: 80px;text-align:right;margin-right:10px;"
      }
    }
  }, [_c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isshowclose,
      expression: "isshowclose"
    }],
    staticStyle: {
      float: "right",
      "margin-right": "60px",
      "margin-top": "-55px"
    },
    on: {
      click: _vm.cancel
    }
  }, [_c("a-icon", {
    attrs: {
      type: "close"
    }
  })], 1), _c("div", [_c("img", {
    staticStyle: {
      width: "60px",
      height: "44.73px",
      "margin-left": "20px",
      "margin-right": "10px"
    },
    attrs: {
      src: _vm.splitImg(_vm.goods),
      alt: "货号"
    },
    on: {
      click: function click($event) {
        _vm.preview(_vm.splitImg(_vm.goods));
      }
    }
  }), _c("span", [_vm._v("货号：" + _vm._s(_vm.goods.sku))])]), _c("a-form-item", {
    attrs: {
      label: "限购数量"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["count", {
        initialValue: 1,
        rules: [{
          required: true,
          message: "请填写限购数量！"
        }]
      }],
      expression: "[\n                    'count',\n                    {\n                        initialValue: 1,\n                        rules: [\n                            {\n                                required: true,\n                                message: '请填写限购数量！',\n                            },\n                        ],\n                    },\n                ]"
    }],
    attrs: {
      disabled: ""
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "活动配色"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["color", {
        rules: [{
          required: true,
          message: "请填写活动配色！"
        }, {
          pattern: /^[#0-9a-zA-Z]*$/g,
          message: "请输入字母或数字！"
        }]
      }],
      expression: "[\n                    'color',\n                    {\n                        rules: [\n                            {\n                                required: true,\n                                message: '请填写活动配色！',\n                            },\n                            {\n                                pattern: /^[#0-9a-zA-Z]*$/g,\n                                message: '请输入字母或数字！',\n                            },\n                        ],\n                    },\n                ]"
    }],
    attrs: {
      disabled: _vm.isshowcount,
      placeholder: " 101010"
    },
    on: {
      change: _vm.changecolor
    }
  }, [_c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isshowcolor,
      expression: "isshowcolor"
    }],
    attrs: {
      slot: "prefix"
    },
    slot: "prefix"
  }, [_vm._v("#")]), _c("div", {
    staticClass: "setcolor",
    style: {
      backgroundColor: _vm.setcolor(_vm.color)
    },
    attrs: {
      slot: "suffix"
    },
    slot: "suffix"
  })]), _c("span", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isshowcolortip,
      expression: "isshowcolortip"
    }],
    staticStyle: {
      color: "red"
    }
  }, [_vm._v("请输入六位数字和字母")])], 1), _c("a-form-item", {
    staticStyle: {
      "font-size": "12px"
    },
    attrs: {
      label: "活动图片"
    }
  }, [_c("UploadImg", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["imgurl", {
        rules: [{
          required: true,
          message: "请上传图片！"
        }],
        initialValue: ""
      }],
      expression: "[\n                    'imgurl',\n                    {\n                        rules: [\n                            {\n                                required: true,\n                                message: '请上传图片！',\n                            },\n                        ],\n                        initialValue: '',\n                    },\n                ]"
    }],
    attrs: {
      disabled: _vm.isshowcount,
      listType: "picture-card",
      max: 1,
      imgProportion: _vm.imgProportion,
      memorySize: "200kb"
    },
    on: {
      previewImg: _vm.previewImg
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "限量发售价",
      extra: "注：不售卖的尺码可不填写，APP端则不显示"
    }
  }, [_c("a-table", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["saleprice", {
        rules: [{}]
      }],
      expression: "[\n                    'saleprice',\n                    {\n                        rules: [{}],\n                    },\n                ]"
    }],
    staticStyle: {
      width: "262px"
    },
    attrs: {
      bordered: "",
      pagination: false,
      dataSource: _vm.size,
      columns: _vm.pricecolumns,
      rowKey: function rowKey(it, i) {
        return i;
      },
      loading: _vm.loading
    },
    scopedSlots: _vm._u([_vm._l(_vm.pricecolumns, function (_ref) {
      var key = _ref.key;
      return {
        key: key,
        fn: function fn(text, record, index) {
          return [key == "size" ? _c("span", [_c("span", [_vm._v(" " + _vm._s(record.size) + " ")])]) : key == "price" ? _c("span", {
            staticClass: "updateinput"
          }, [_c("a-input", {
            key: index,
            staticStyle: {
              width: "100px"
            },
            attrs: {
              disabled: _vm.isshowcount,
              placeholder: "请填写售价",
              type: "number"
            },
            on: {
              change: _vm.changeprice
            },
            model: {
              value: record.price,
              callback: function callback($$v) {
                _vm.$set(record, "price", _vm._n($$v));
              },
              expression: "record.price"
            }
          })], 1) : key == "count" ? _c("span", [_c("span", [_vm._v(" " + _vm._s(record.count) + " ")])]) : _vm._e()];
        }
      };
    })], null, true)
  })], 1), _c("span", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isshowpriceinfo,
      expression: "isshowpriceinfo"
    }],
    staticStyle: {
      color: "red",
      "margin-left": "90px"
    }
  }, [_vm._v("请输入正整数!")]), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isShowButton,
      expression: "isShowButton"
    }],
    staticClass: "combinbtn"
  }, [_c("a-button", {
    staticClass: "btn_1",
    staticStyle: {
      background: "#f5f5f5",
      border: "0",
      "margin-right": "26px"
    },
    attrs: {
      size: "large"
    },
    on: {
      click: _vm.cancelbottom
    }
  }, [_vm._v("取消")]), _c("a-button", {
    attrs: {
      type: "primary",
      size: "large"
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v("确定")])], 1)], 1), _c("PreviewPic", {
    attrs: {
      isShowPic: _vm.previewVisible,
      previewImage: _vm.previewImage
    },
    on: {
      closePreviewpic: _vm.handleCancel
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };